import React from "react"
import { Redirect, Router } from "@reach/router"
import { Dashboard } from "../../client-only-pages/Dashboard"
import { PageEditor } from "../../client-only-pages/PageEditor"
import { navigate } from "gatsby"
import auth from  '../../utils/auth';
import Brands from "../../client-only-pages/Brands/Brands"
import BrandDetail from "../../client-only-pages/BrandDetail/BrandDetail"
import NewBrand from "../../client-only-pages/NewBrand/NewBrand"
import { Users } from "../../client-only-pages/Users"
import { NewUser } from "../../client-only-pages/NewUser"
import { UserDetail } from "../../client-only-pages/UserDetail"
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import Analytics from "../../client-only-pages/Analytics/Analytics"
import { useQuery } from "react-query"
import api from "../../utils/api"
import ErrorBoundary from "../../components/ErrorBoundary"
import styled from 'styled-components'

const App = () => {
  const { isLoading, error, data, isFetching } = useQuery(
    `profile`,
    api.profile,
  );

  const isSuperAdmin = ['superadmin'].includes(data?.data?.role?.name);
  
  return (
    <ErrorBoundary>
      <SToastContainer />
      <Router basepath='/page'>
        {/* <PrivateRoute path='/analytics?metrics=all&page_id=1&brand_id=1&start_at=2021-12-10&end_at=2021-12-14' component={Analytics}/> */}
        {
          !isSuperAdmin && (
            <>
              <PrivateRoute path='/analytics' component={Analytics}/>
              <PrivateRoute path='/dashboard' component={Dashboard}/>
              <PrivateRoute path=":pageUrl" component={PageEditor}/>
            </>
          )
        }
        {
          isSuperAdmin && (
            <>
              <PrivateRoute path='/brands' component={Brands}/>
              <PrivateRoute path='/brands/new' component={NewBrand}/>
              <PrivateRoute path='/brands/:brandId' component={BrandDetail}/>
              <PrivateRoute path='/users' component={Users}/>
              <PrivateRoute path='/users/new' component={NewUser}/>
              <PrivateRoute path='/users/:userId' component={UserDetail}/>
              <Redirect from='/dashboard' to='/page/brands' noThrow/>
            </>
          )
        }
        {/* <PrivateRoute path='/new' component={PageEditor}/> */}
      </Router>
    </ErrorBoundary>
  )
}

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  if (!auth.getToken() && location.pathname !== `/`) {
    if (typeof window !== `undefined`) {
      navigate("/")
    }

    return null
  }
  return <Component {...rest}/>;
}

export default App

const SToastContainer = styled(ToastContainer)`
.Toastify__toast--error {
    border-left: 6px solid #EB5757;
    border-radius: 16px !important;
    background: #FAE1E2 !important;
}
.Toastify__toast--error::after {
  position: absolute;
  color: #333333;
  font-size: 15px;
  font-weight: 700;
  left:265px;
  padding-top: 14px !important;
}
.Toastify__toast--error::before {
  position:relative; 
  z-index:100000;
  left:12px;
  top:6px;
}
.Toastify__toast--info {
    border-left: 6px solid #54c7ec;
    border-radius: 16px !important;
    background: #dcf1f8 !important;
}
.Toastify__toast--info::after {
  position: absolute;
  color: #333333;
  font-size: 15px;
  font-weight: 700;
  left:265px;
  padding-top: 14px !important;
}
.Toastify__toast--info::before {
  position:relative; 
  z-index:100000;
  left:12px;
  top:6px;
}
.Toastify__toast--success {
  border-left: 6px solid #3A9EA5 !important;
  border-radius: 16px !important;
  background: #F0F9FA !important;
}
.Toastify__toast--success::before {
  position:relative; 
  z-index:100000;
  left:12px;
  top:6px;
}
.Toastify__toast--success::after {
  position: absolute;
  color: #333333;
  font-size: 15px;
  font-weight: 700;
  left:265px;
  padding-top: 14px !important;
}
.Toastify__toast--warning {
  border-left: 6px solid #E78326  !important;
  border-radius: 16px !important;
  background: #fbfaf8 !important;
}
.Toastify__toast--warning::before {
  position:relative; 
  z-index:100000;
  left:12px;
  top:6px;
}  
.Toastify__toast--warning::after {
  position: absolute;
  color: #E78326;
  font-size: 15px;
  font-weight: 700;
  left:265px;
  padding-top: 14px !important;
}
.Toastify__toast-body {
  color: #444C63    ;
  font-size: 16px;
  padding: 0px;
  width: 100%;
  font-weight: 400;
  margin-left: 15px !important;
} 
.Toastify__toast > button>  svg {
    display: none;
}
`;