/**@jsx jsx */
import React, { useState } from "react";
import { useParams } from "@reach/router"
import { Composition } from 'atomic-layout';
import { Box, Card, Button, Container, Flex, IconButton, Image, Text } from '@theme-ui/components';
import MyButton from '../../components/Button';
import { useForm } from 'react-hook-form';
import { MdEdit, MdOutlineRemoveRedEye } from "react-icons/md";
import { FaEllipsisV, FaPlus, FaShare } from "react-icons/fa";
import { Input, jsx, Label, Spinner } from 'theme-ui'
import { QueryClientProvider, useMutation, useQuery } from "react-query";
import { navigate } from "gatsby";
import PageCards from "./compositions/Content/PageCards";
import ApplicationShell from "../../components/Layout/ApplicationShell";
import { Table } from "../../components/Table";
import api from "../../utils/api";
import brandAPI from '../../utils/api/brand';

import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Selection from "../../components/Editor/Selection";
import Toast from "../../components/Editor/Toast";

interface UserData {
  email?: string;
}

const schema: Yup.SchemaOf<UserData> = Yup.object({
  email: Yup.string().required('Email harus diisi!').email('Format email tidak valid'),
});

const areas = `
  header
  content
  email
  brand
  role
`;

const NewUserPage = () => {
  const {
    register, handleSubmit, formState: { errors }, watch, setValue
  } = useForm({
    defaultValues: {
      email: '',
      brand_id: '1',
      role_ids: ['2']
    },
    resolver: yupResolver(schema)
  });

  const role = watch('role_ids');

  const onSuccess = ({ data }) => {
    navigate('/page/users');
    Toast({
      title: 'Undangan Berhasil',
      message: 'Undangan berhasil dikirim ke email pengguna',
      type: 'success',
    });
  };

  const onError = (error: any) => {
    if (error) {
      console.log(error);
    }
  };

  const { isLoading: brandsLoading, error, data, isFetching } = useQuery(
    `list-brand`,
    brandAPI.listBrands,
  );

  const brandOpts = data?.data?.map(brand => {
    return {
      value: brand.id,
      label: brand.name
    }
  })

  const {
    isLoading,
    mutate,
  } = useMutation((values: any) => api.inviteUser(values), { onSuccess, onError });

  const borderStyle = {
    borderRadius: '12px',
    border: '1px solid',
    borderColor: 'gray1',
    bg: 'white',
    height: '40px',
  }

  return (
    <ApplicationShell>
      <Composition
        width={'100%'}
        inline
        areas={areas}
        templateRows={`1fr 12fr`}
        gap={20}
        padding={32}
      >
        {({ Header: HeaderWrapper, Content: ContentWrapper, Email, Brand, Role }) => (
          <>
            <HeaderWrapper>
              <Box>
                <Text variant="headline" color="#B9A511">Tambah Pengguna Baru</Text>
              </Box>
              {/* <Box>
                <Text variant="body">Desain page kamu dan pantau sekilas aktivitas terbaru</Text>        
              </Box> */}
            </HeaderWrapper>
            <ContentWrapper>
              <>
                <Card
                  variant='sheet'
                >
                  <Box as="form" onSubmit={(e) => e.preventDefault()}>
                    {/* <UserName marginBottom={15}>
                      <Label htmlFor="name" mb={2}>Nama User</Label>
                      <Input
                        {...register("name")}
                        variant={errors.name && 'error' || 'input'}
                        name="name"
                        id="name"
                      />
                      {errors.name && (
                        <Text sx={{ fontSize: '12px'}}>{errors.name.message}</Text>
                      )}
                    </UserName> */}
                    <Email marginBottom={15}>
                      <Label htmlFor="email" mb={2}>Email</Label>
                      <Input
                        {...register("email")}
                        variant={errors.email && 'error' || 'input'}
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Email"
                      />
                      {errors.email && (
                        <Text color="error" sx={{ fontSize: '12px'}}>{errors.email.message}</Text>
                      )}
                    </Email>
                    {/* <Password marginBottom={15}>
                      <Label htmlFor="password" mb={2}>Password</Label>
                      <Input
                        variant={errors.password && 'error' || 'input'}
                        type='password'
                        {...register("password")}
                      />
                      {errors.password && (
                        <Text sx={{ fontSize: '12px'}}>{errors.password.message}</Text>
                      )}
                    </Password>
                    <Password marginBottom={15}>
                      <Label htmlFor="password_confirmation" mb={2}>Password Confirmation</Label>
                      <Input
                        variant={errors.password_confirmation && 'error' || 'input'}
                        type='password'
                        {...register("password_confirmation")}
                      />
                      {errors.password_confirmation && (
                        <Text sx={{ fontSize: '12px'}}>{errors.password_confirmation.message}</Text>
                      )}
                    </Password> */}
                    <Brand marginBottom={15}>
                      <Selection
                        variant="input"
                        id="brand_id"
                        name="brand_id"
                        label="Brand"
                        sx={borderStyle}
                        onChange={(e) => setValue('brand_id', e.target.value)}
                        defaultValue="1"
                        placeholder="Ayana"
                      >
                        {brandOpts?.map(brand => (
                          <option value={brand.value}>{brand.label}</option>
                          ))}
                      </Selection>
                    </Brand>
                    <Role marginBottom={15}>
                      <Selection
                        variant="input"
                        id="role_ids"
                        name="role_ids"
                        label="Role"
                        sx={borderStyle}
                        onChange={(e) => setValue('role_ids', [e.target.value])}
                        defaultValue="2"
                      >
                        <option value="2">Marketing Analytics</option>
                        <option value="3">Brand</option>
                      </Selection>
                    </Role>
                    <MyButton
                      onClick={handleSubmit(mutate)}
                      isLoading={isLoading}
                      style={{ float: 'right'}}
                      mt={4}
                    >
                      Submit
                    </MyButton>
                  </Box>
                </Card>
              </>
            </ContentWrapper>
          </>
        )}
      </Composition>
    </ApplicationShell>
  )
}

export default NewUserPage;