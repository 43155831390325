/**@jsx jsx */
import React, { useState } from "react";
import {
  useTable,
  usePagination,
} from 'react-table'
import { Composition } from 'atomic-layout';
import { Box, Card, Flex, IconButton, Text , Link} from '@theme-ui/components';
import { MdContentCopy, MdEdit, MdOutlineMoreVert, MdOutlineRemoveRedEye } from "react-icons/md";
import { FaArrowCircleLeft, FaArrowCircleRight, FaPlus, FaShare, FaRegTimesCircle, FaTrashAlt } from "react-icons/fa";
import { Badge, jsx } from 'theme-ui'
import { useMutation, useQuery } from "react-query";
import { navigate } from "gatsby";
import Button from '../../../../components/Button';
import api from '../../../../utils/api';
import IconButtonWithTooltip from "../../../../components/IconButtonWithTooltip";
import Microsite from "../../../../components/Microsite";
import { usePopper } from 'react-popper';
import { BsFacebook, BsLine, BsWhatsapp } from "react-icons/bs";
import { AiFillTwitterCircle } from "react-icons/ai";
import copyToClipboard from '../../../../utils/copyToClipboard';
import useOnclickOutside from "react-cool-onclickoutside";
import Toast from "../../../../components/Editor/Toast";

const cardAreas = `
  thumbnail
  thumbnailOverlay
  badge
  tooltip
`

const outerCardAreas = `
  deleteIcon
  card
`

const BoxPopper = {
  backgroundColor: 'white',
  width: '200px',
  py: 4,
  px: 3,
  borderRadius: 'small',
  boxShadow: '0 0 8px 0 rgba(0, 0, 0, 0.14)',
  height: '320px',
}

const PageCard = ({ brandName, content, state, url_code, refetch }) => {
  const isPublished = (state === 'published')
  const [showModal, setShowModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [showPopper, setShowPopper] = useState(false);
  const [popperElement, setPopperElement] = useState(null);
  const [referenceElement, setReferenceElement] = useState(null);
  const [hovered, setHovered] = useState(false);
  const [arrowElement, setArrowElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      {
        name: 'arrow',
        options: {
          element: arrowElement
        }
      },
      {
        name: "offset",
        enabled: true,
        options: {
          offset: [-50, -20]
        }
      },
    ],
    placement: 'bottom-start',
  });

  const closePopper = () => {
    setShowModal(false);
    setShowShareModal(false);
    setShowPopper(false)
  }

  const ref = useOnclickOutside(() => closePopper())

  function handleEvent(type: string, event: any) {
    switch (type) {
      case 'mouseEnter':
        return setHovered(true);
      case 'mouseLeave':
        return setHovered(false);
      default:
        return;
    }
  }

  const {
    isLoading: isLoadingDeletePage,
    mutate: mutateDeletePage,
  } = useMutation(
    `page-${url_code}`,
    (pageUrl: string) => api.deletePage(pageUrl),
    {
      onSuccess: ({ data }) => {
        refetch();
        setShowPopper(!showPopper);
        Toast({
          title: 'Delete Berhasil',
          message: 'Page berhasil dihapus',
          type: 'success',
        });
      }
    },
  );

  return (
    <Card
      onClick={() => {
        navigate(`/page/${url_code}`)
      }}
      sx={{ position: 'relative' }}
      ref={ref}
    >
      <Box
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setShowPopper(!showPopper);
        }}
        ref={setReferenceElement}
        sx={{ position: 'absolute', right: '5px', cursor: 'pointer' }}
      >
        <MdOutlineMoreVert size={25} />
      </Box>

      {
        showModal && (
          <Box
            onClick={(e) => e.stopPropagation()}
            p={3}
            bg="gray1"
            sx={{
              zIndex: '1055',
              position: 'fixed',
              top: 6,
              borderRadius: 8,
              width: '21%'
            }}
          >
            <Text>Apakah Anda Yakin Akan Menghapus Page ini?</Text>
            <Flex mt={3} sx={{ justifyContent: 'end' }}>
              <Button
                mr={2}
                onClick={(e) => {
                  e.stopPropagation();
                  setShowPopper(!showPopper);
                  setShowModal(!showModal);
                }}
              >
                Tidak
              </Button>
              <Button
                variant="error"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowPopper(!showPopper);
                  setShowModal(!showModal);
                  mutateDeletePage(url_code);
                }}
              >
                Ya
              </Button>
            </Flex>
          </Box>
        )
      }

      {showShareModal && (
        <Box
          onClick={(e) => e.stopPropagation()}
          p={3}
          sx={{
            zIndex: '1055',
            position: 'fixed',
            top: 7,
            borderRadius: 8,
            width: '21%',
            bg: 'gray2'
          }}
        >
          <FaRegTimesCircle sx={{ position: 'absolute', right: 10 }} onClick={(e) => {e.stopPropagation(); setShowShareModal(!showShareModal);} } />
          <h3 sx={{ textAlign: 'center' }}>Bagikan page kamu</h3>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            mt: 4
          }}>
            <Link
              variant="styles.links.shareLink"
              href={`https://api.whatsapp.com/send?text=https://link.hypefast.id/${brandName}`}
              target="_blank"
              onClick={() => { setShowShareModal(!showShareModal); setShowPopper(false) }}
            >
              <BsWhatsapp sx={{ position: 'relative', top: '2px' }} fill="limegreen" />
              <Text ml={3} color="" >WhatsApp</Text>
            </Link>
            <Link
              variant="styles.links.shareLink"
              href={`https://line.me/R/msg/text/?https://link.hypefast.id/${brandName}`}
              target="_blank"
              onClick={() => { setShowShareModal(!showShareModal); setShowPopper(false) }}
            >
              <BsLine sx={{ position: 'relative', top: '2px' }} fill="limegreen" />
              <Text ml={3}>Line</Text>
            </Link>
            <Link
              variant="styles.links.shareLink"
              href={`https://www.facebook.com/sharer/sharer.php?u=https://link.hypefast.id/${brandName}&quote=https://link.hypefast.id/${brandName}`}
              target="_blank"
              onClick={() => { setShowShareModal(!showShareModal); setShowPopper(false) }}
            >
              <BsFacebook sx={{ position: 'relative', top: '2px' }} fill="dodgerblue" />
              <Text ml={3}>Facebook</Text>
            </Link>
            <Link 
              variant="styles.links.shareLink"
              href={`https://twitter.com/intent/tweet?text=https://link.hypefast.id/${brandName}`}
              target="_blank"
              onClick={() => { setShowShareModal(!showShareModal); setShowPopper(false) }}
            >
              <AiFillTwitterCircle sx={{ position: 'relative', top: '2px' }} fill="dodgerblue" />
              <Text ml={3}>Twitter</Text>
            </Link>
            <Box variant="styles.box.shareLink" onClick={() => { copyToClipboard(`https://link.hypefast.id/${brandName}`); setShowShareModal(!showShareModal);}}>
              <MdContentCopy sx={{ position: 'relative', top: '2px' }} />
              <Text ml={2}>Salin Link</Text>
            </Box>
          </Box>
        </Box>
      )}

      <Card
        variant='full'
        onMouseEnter={(e) => handleEvent('mouseEnter', e)} onMouseLeave={(e) => handleEvent('mouseLeave', e)}
      >
        <Composition
          width={'100%'}
          height={'100%'}
          inline
          areas={cardAreas}
        >
          {({ Badge: BadgeWrapper, Thumbnail, ThumbnailOverlay, Tooltip }) => (
            <>
              {
                showPopper ? (
                  <Box
                    ref={setPopperElement}
                    sx={BoxPopper}
                    style={styles.popper}
                    {...attributes.popper}
                  >
                    <Flex sx={{ flexDirection: 'column' }}>
                      <Button
                        mb={3}
                        onClick={e => {
                          e.stopPropagation();
                          navigate(`/page/${url_code}`);
                        }}
                      >
                        Edit
                      </Button>
                      {isPublished && (
                        <Button
                          mb={3}
                          onClick={e => {
                            e.stopPropagation();
                            navigate(`/${brandName}`);
                          }}
                        >
                          Preview
                        </Button>
                      )}
                      <Button mb={3} onClick={e => e.stopPropagation()}>
                        Pengaturan Page
                      </Button>
                      {isPublished && (
                        <Button variant="primary" mb={3} onClick={e => { e.stopPropagation(); setShowShareModal(!showShareModal); }}>
                          Bagikan
                        </Button>
                      )}
                      {!isPublished && (
                        <Button
                          leftIcon={<FaTrashAlt/>}
                          variant="outline"
                          mb={3}
                          disabled={isLoadingDeletePage}
                          onClick={e => {
                            e.stopPropagation();
                            setShowModal(!showModal);
                          }}
                        >
                          Hapus
                        </Button>
                      )}
                    </Flex>
                  </Box>
                ) : (
                  <>
                    <Thumbnail
                      row={'1/5'}
                      col={'1/2'}
                      minHeight={0}
                      minWidth={0}
                    >
                      {/* <Box sx={{ width: '200px', height: '304px', mt: '24px', overflow: 'hidden', boxShadow: '0 6.73418px 10.1013px rgb(0 0 0 / 5%)', borderRadius: '12px', position: 'relative' }} onMouseEnter={(e) => handleEvent('mouseEnter', e)} onMouseLeave={(e) => handleEvent('mouseLeave', e)}> */}
                      <Box sx={{ overflow: 'hidden', borderRadius: '12px' }}>
                        <Microsite content={content}/>
                      </Box>
                    </Thumbnail>
                    <ThumbnailOverlay
                      row={'1/5'}
                      col={'1/2'}
                      sx={{
                        zIndex: 999,
                        bg: 'transparent',
                      }}
                    >
                      <Box onClick={(e) => {
                        navigate(`/page/${url_code}`);
                        e.stopPropagation();
                      }} sx={{ height: '100%', borderRadius: '12px' }}>
                      </Box>
                    </ThumbnailOverlay>
                    <BadgeWrapper
                      row={'1/5'}
                      col={'1/2'}
                      flexDirection='column'
                      justifyContent='flex-start'
                      alignItems='flex-start'
                      flex
                    >
                      <Box
                        sx={{
                          p: 2,
                          zIndex: 999, // Some components from microsite overlap the text so zIndex to the rescue.
                        }}
                      >
                        <Badge
                          ml={1}
                        >
                          <Text
                            variant='body'
                          >
                            {state}
                          </Text>
                        </Badge>

                      </Box>
                    </BadgeWrapper>
                    <Tooltip
                      row={'4/4'}
                      col={'1/2'}
                      flexDirection='column'
                      justifyContent='flex-end'
                      flex
                    >
                      {hovered && (
                        // <Flex sx={{ alignItems: 'center', justifyContent: 'space-evenly', position: 'sticky', width: '100%', top: 0, bottom: 0 }}>
                        <Flex sx={{ p: 2, alignItems: 'center', justifyContent: 'space-evenly', zIndex: 999, }}>
                          <Flex sx={{ alignItems: 'center', justifyContent: 'center' }}>
                            <IconButtonWithTooltip
                              onClick={() => {
                                navigate(`/page/${url_code}`)
                              }}
                              tooltipText='Edit'
                              sx={{ bg: 'gray1', borderRadius: '50%' }}
                            >
                              <MdEdit size={15} />
                            </IconButtonWithTooltip>
                          </Flex>
                          {isPublished && <Flex sx={{ alignItems: 'center', justifyContent: 'center' }}>
                            <IconButtonWithTooltip
                              sx={{bg: 'gray1', borderRadius: '50%' }}
                              tooltipText='Preview'
                              onClick={(e) => {
                                console.log('Test');
                                e.stopPropagation();
                                navigate(`/${brandName}`)
                              }}
                            >
                              <MdOutlineRemoveRedEye size={15} />
                            </IconButtonWithTooltip>
                          </Flex> || null}
                          {/* Share functionality is temporarily commented */}
                          {isPublished && <Flex sx={{ alignItems: 'center', justifyContent: 'center' }}>
                            <IconButtonWithTooltip
                              sx={{ bg: 'gray1', borderRadius: '50%' }}
                              tooltipText='Share'
                              onClick={(e) => {
                                e.stopPropagation();
                                setShowShareModal(!showShareModal);
                              }}
                            >
                              <FaShare size={15} />
                            </IconButtonWithTooltip>
                          </Flex> || null}
                        </Flex>
                      )}

                    </Tooltip>
                  </>
                )
              }
              <div ref={setArrowElement} style={styles.arrow} />
            </>
          )}
        </Composition>
      </Card>
    </Card>
  )
}

const NewPageCard = () => {
  return (
    <Flex sx={{ bg: 'unset', position: 'relative', margin: '4px', alignItems: 'center', borderRadius: 'm', justifyContent: 'center' }}>
      <Flex sx={{ justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
        <Flex onClick={() => {
          navigate('/page/new')
        }} sx={{ width: '200px', height: '304px', cursor: 'pointer', bg: 'gray4', border: '1px solid', borderColor: 'highlight', alignItems: 'center', justifyContent: 'center', boxShadow: '0 6.73418px 10.1013px rgb(0 0 0 / 5%)', borderRadius: '12px' }}>
          <FaPlus size={25} sx={{ color: 'primary' }} />
        </Flex>
        <Box sx={{ mt: '16px' }}>
          <Button
            variant="outline"
            rightIcon={<FaPlus />}
            onClick={() => {
              navigate('/page/new')
            }}
          >
            Buat baru
          </Button>
        </Box>
      </Flex>
    </Flex>
  )
}

const columns = [
  {
    Header: 'Brand Name',
    accessor: 'brand.name',
  },
  {
    Header: 'Content',
    accessor: 'content',
  },
  {
    Header: 'State',
    accessor: 'state',
  },
  {
    Header: 'URL Code',
    accessor: 'url_code',
  },
];


const PageCards = () => {
  const [data, setData] = useState([]);
  const { isLoading, error, isFetching, refetch } = useQuery(
    `pages`,
    api.pages
  ,{
    onSuccess: (data) => {
      data.data.length && setData(data.data);
    }
  });

  const {
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      data,
      columns,
      initialState: {
        pageSize: 5,
      },
    },
    usePagination,
  )


  return (
    <Box>
      <Flex
        sx={{
          justifyContent: 'space-between',
        }}
      >
        <Box>
          <Text>My Pages</Text>
        </Box>
        <Box>
          <IconButton
            sx={{opacity: !canPreviousPage ? 0.6 : 1}}
            onClick={() => {
              previousPage()
            }}
          >
            <FaArrowCircleLeft size={30}/>
          </IconButton>
          <IconButton
            sx={{opacity: !canNextPage ? 0.6 : 1}}
            onClick={() => {
              nextPage()
            }}
          >
            <FaArrowCircleRight size={30}/>
          </IconButton>
        </Box>
      </Flex>
      <Composition
        width={'100%'}
        inline
        // templateCols={`repeat(auto-fit, 300px)`}
        templateCols={`1fr`}
        templateColsMd={`1fr 1fr`}
        templateColsLg={`1fr 1fr 1fr`}
        autoRows='400px'
        gap={20}
        paddingVertical={32}
      >
        <NewPageCard/>
        {page.map((p) => {
          prepareRow(p);
          return (
            <PageCard refetch={refetch} key={p.values.url_code} brandName={p.values['brand.name']} content={p.values.content} state={p.values.state} url_code={p.values.url_code}/>
          )
        })}
      </Composition>
    </Box>
  )
}

export default PageCards;

const dummyData = [
  {
    id: 1,
    title: "rubyh",
    urlCode: "test-url",
    description: "rubyh@rubyh.co",
    thumbnail: null,
    content: "this is descriptionssss",
    createdById: 1,
    brandId: 1,
    state: "draft",
    attachments: null,
    slug: "test-url"
  }
]