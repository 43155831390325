/**@jsx jsx */
import React, { useState } from "react";
import { useParams } from "@reach/router"
import { Composition } from 'atomic-layout';
import { Box, Card, Button, Container, Flex, IconButton, Image, Text, Textarea } from '@theme-ui/components';
import MyButton from '../../components/Button';
import { useForm } from 'react-hook-form';
import { MdEdit, MdOutlineRemoveRedEye } from "react-icons/md";
import { FaEllipsisV, FaPlus, FaShare } from "react-icons/fa";
import { Input, jsx, Label, Spinner } from 'theme-ui'
import { QueryClientProvider, useMutation, useQuery } from "react-query";
import { navigate } from "gatsby";
import PageCards from "./compositions/Content/PageCards";
import brandAPI from '../../utils/api/brand';
import ApplicationShell from "../../components/Layout/ApplicationShell";
import { Table } from "../../components/Table";
import api from "../../utils/api/brand";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Toast from "../../components/Editor/Toast";

interface BrandData {
  name?: string;
  email_pic?: string;
  phone?: string;
  fallback_url?: string;
  description?: string;
}

const schema: Yup.SchemaOf<BrandData> = Yup.object({
  name: Yup.string().required('Nama Brand harus diisi!'),
  phone: Yup.string().required('No. Handphone harus diisi!'),
  fallback_url: Yup.string().required('Linktree harus diisi!'),
  email_pic: Yup.string().required('Email PIC harus diisi!').email('Format email tidak valid'),
  description: Yup.string().required('Deskripsi harus diisi!'),
});

const areas = `
  header
  content
  brandName
  email
  phone
  description
  linktree
`;

const NewBrandPage = () => {
  const {
    register, handleSubmit, formState: { errors },
  } = useForm({
    resolver: yupResolver(schema)
  });

  const onSuccess = ({ data }) => {
    navigate('/page/brands');
    Toast({
      title: 'Create Berhasil',
      message: 'Brand baru berhasil dibuat',
      type: 'success',
    });
  };

  const onError = (error: any) => {
    if (error) {
      console.log(error);
    }
  };
  
  const {
    isLoading,
    mutate,
  } = useMutation((values: any) => api.createBrand(values), { onSuccess, onError });

  return (
    <ApplicationShell>
      <Composition
        width={'100%'}
        inline
        areas={areas}
        templateRows={`1fr 12fr`}
        gap={20}
        padding={32}
      >
        {({ Header: HeaderWrapper, Content: ContentWrapper, BrandName, Email, Phone, Description, Linktree }) => (
          <>
            <HeaderWrapper>
              <Box>
                <Text variant="headline" color="#B9A511">Tambah Brand Baru</Text>
              </Box>
            </HeaderWrapper>
            <ContentWrapper>
              <>
                <Card
                  variant='sheet'
                >
                  <Box as="form" onSubmit={(e) => e.preventDefault()}>
                    <BrandName marginBottom={15}>
                      <Label htmlFor="name">Nama Brand</Label>
                      <Input
                        {...register("name")}
                        variant={errors.name && 'error' || 'input'}
                        name="name"
                        id="name"
                        placeholder="Nama Brand"
                      />
                      {errors.name && (
                        <Text color={'error'} sx={{ fontSize: '12px'}}>{errors.name.message}</Text>
                      )}
                    </BrandName>
                    <Email marginBottom={15}>
                      <Label htmlFor="email_pic">Email PIC</Label>
                      <Input
                        {...register("email_pic")}
                        variant={errors.email_pic && 'error' || 'input'}
                        type="email"
                        name="email_pic"
                        id="email_pic"
                        placeholder="Email PIC"
                      />
                      {errors.email_pic && (
                        <Text color={'error'} sx={{ fontSize: '12px'}}>{errors.email_pic.message}</Text>
                      )}
                    </Email>
                    <Phone marginBottom={15}>
                      <Label htmlFor="phone">No. Handphone</Label>
                      <Input
                        {...register("phone")}
                        variant={errors.phone && 'error' || 'input'}
                        type="number"
                        name="phone"
                        id="phone"
                        placeholder="No. Handphone"
                        onKeyDown={ e => ( e.keyCode === 69 || e.keyCode === 190 ) && e.preventDefault() }
                      />
                      {errors.phone && (
                        <Text color={'error'} sx={{ fontSize: '12px'}}>{errors.phone.message}</Text>
                      )}
                    </Phone>
                    <Linktree marginBottom={15}>
                      <Label htmlFor="fallback_url">Linktree</Label>
                      <Input
                        {...register("fallback_url")}
                        variant={errors.fallback_url && 'error' || 'input'}
                        name="fallback_url"
                        id="fallback_url"
                        placeholder="Linktree"
                      />
                      {errors.fallback_url && (
                        <Text color={'error'} sx={{ fontSize: '12px'}}>{errors.fallback_url.message}</Text>
                      )}
                    </Linktree>
                    <Description marginBottom={15}>
                      <Label htmlFor="description">Deskripsi</Label>
                      <Textarea
                        {...register("description")}
                        variant={errors.description && 'error' || 'input'}
                        name="description"
                        placeholder="Deskripsi"
                      />
                      {errors.description && (
                        <Text color={'error'} sx={{ fontSize: '12px'}}>{errors.description.message}</Text>
                      )}
                    </Description>
                    <MyButton
                      style={{ float: 'right'}}
                      onClick={handleSubmit(mutate)}
                      isLoading={isLoading}
                      mt={4}
                    >
                      Submit
                    </MyButton>
                  </Box>
                </Card>
              </>
            </ContentWrapper>
          </>
        )}
      </Composition>
    </ApplicationShell>
  )
}

export default NewBrandPage;