import React, { useState, FC, memo } from 'react';
// import { Button as BsButton, ButtonProps as BsButtonProps, Spinner } from 'react-bootstrap';
import { Box, IconButton, IconButtonProps as ThemeUIIconButtonProps, Card, Text, } from 'theme-ui';
import { IconType } from 'react-icons/lib';
import { usePopper } from 'react-popper';

import Button from './Button';


enum Variant {
  PRIMARY,
  SECONDARY
};

interface IconButtonProps extends ThemeUIIconButtonProps {
  tooltipText: string;
}


const ProfileButton: FC<IconButtonProps> = ({
  children, tooltipText, variant = 'icon', sx, ...props
}) => {
  const [showPopper, setShowPopper] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      {
        name: 'arrow',
        options: {
          element: arrowElement
        }
      },
      {
        name: "offset",
        enabled: true,
        options: {
          offset: [0, 10]
        }
      },
    ],
  });

  return (
    <>
      <IconButton
        sx={sx}
        variant={variant}
        ref={setReferenceElement}
        onClick={() => {
          setShowPopper(!showPopper);
        }}
        onMouseEnter={() => setShowPopper(true)}
        onMouseLeave={() => setShowPopper(false)}
        {...props}
      >
        {children}
      </IconButton>

      <div ref={setPopperElement} style={styles.popper} {...attributes.popper}>
        {
          showPopper && (
            <Box
              sx={{
                backgroundColor: 'white',
                p: 2,
                borderRadius: 'small',
                boxShadow: '0 0 8px 0 rgba(0, 0, 0, 0.14)',
              }}
            >
              <Text
                variant='body'
              >
                {tooltipText}
              </Text>
            </Box>
          )
        }
        <div ref={setArrowElement} style={styles.arrow} />
      </div>
    </>
  );
  
}

export default memo(ProfileButton);
