/**@jsx jsx */
import React, { useEffect } from "react";
import { encodeQueryParams, useQueryParams, DateParam, NumberParam, StringParam } from "use-query-params";
import { stringify } from 'query-string';
import { Composition } from 'atomic-layout';
import { Box, Card, Text, Flex, Grid } from '@theme-ui/components';
import { jsx } from 'theme-ui'
import { useQuery } from "react-query";
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import { AnimatedGrid, AnimatedAxis, lightTheme, AnimatedLineSeries, Tooltip, XYChart } from '@visx/xychart';
import { curveLinear } from '@visx/curve';
import cityTemperature, { CityTemperature } from '@visx/mock-data/lib/mocks/cityTemperature';
import api from '../../utils/api';
import ApplicationShell from "../../components/Layout/ApplicationShell";
import xDaysAgo from "../../utils/xDaysAgo";
import { Table } from "../../components/Table";
import { useState } from "react";
import { BsGraphDown, BsGraphUp, BsInfoCircle } from 'react-icons/bs'
import Selection from "../../components/Editor/Selection";
import { Manager, Reference, Popper } from 'react-popper';
import { MarkerCircle } from "@visx/marker";
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css';
import dayjs from 'dayjs';
import { VscCalendar } from 'react-icons/vsc';
import styled from '@emotion/styled';

const areas = `
  header    header
  activity  activity
  links     time
  origins   locations
  socials   devices
`;

const opts = [
  { value: 7, label: '7 hari terakhir'},
  { value: 30, label: '30 hari terakhir'},
  { value: 90, label: '90 hari terakhir'},
  { value: 0, label: 'Custom'},
];

const borderStyle = {
  border: '1px solid',
  borderColor: 'gray1',
}

const LinkColumns = [
  {
    Header: 'Link',
    accessor: 'link_url',
  },
  {
    Header: 'Total Klik',
    accessor: 'total_clicks',
  },
];
const Sosmedcolumns = [
  {
    Header: 'Link',
    accessor: 'link_url',
  },
  {
    Header: 'Total Klik',
    accessor: 'total_clicks',
  },
  {
    Header: 'Total Visitor',
    accessor: 'total_visitors',
  },
];
const OriginColumns = [
  {
    Header: 'Referrer',
    accessor: 'referrer',
  },
  {
    Header: 'Total Clicks',
    accessor: 'data.total_clicks',
  },
  {
    Header: 'Total Visitor',
    accessor: 'data.total_visitors',
  },
];
const DeviceColumns = [
  {
    Header: 'Perangkat',
    accessor: 'device_type',
  },
  {
    Header: 'Total Clicks',
    accessor: 'data.total_clicks',
  },
  {
    Header: 'Total Visitor',
    accessor: 'data.total_visitors',
  },
];
const CountryColumns = [
  {
    Header: 'Country',
    accessor: 'country',
  },
  {
    Header: 'Total Clicks',
    accessor: 'data.total_clicks',
  },
  {
    Header: 'Total Visitor',
    accessor: 'data.total_visitors',
  },
];
const CityColumns = [
  {
    Header: 'City',
    accessor: 'city',
  },
  {
    Header: 'Total Clicks',
    accessor: 'data.total_clicks',
  },
  {
    Header: 'Total Visitor',
    accessor: 'data.total_visitors',
  },
];

const countryPadding = {
  paddingLeft: '40px',
  paddingRight: '40px',
  cursor: 'pointer'
}
const tabStyle = {
  borderRadius: '5px',
  backgroundColor: '#B9A511',
  color: 'white'
}

const countryStyle = {
  ...tabStyle,
  ...countryPadding,
}
const cityPadding = {
  paddingLeft: '80px',
  paddingRight: '80px',
  cursor: 'pointer'
}

const cityStyle = {
  ...tabStyle,
  ...cityPadding
}

const getDate = (d: CityTemperature) => d['date'];
const getVisitorAtDate = (d: CityTemperature) => d['visitor'];
const getClicksAtDate = (d: CityTemperature) => d['click'];
const getCtrAtDate = (d: CityTemperature) => d['ctr'];
const getDurationsAtdate = (d: CityTemperature) => d['duration'];


const animationTrajectory = 'center';
const numTicks = 4;

const LineChart = ({
  width,
  height,
  data,
  dataKey,
}) => {
  const accessors = {
    x: getDate,
    y: {
      visitor: getVisitorAtDate,
      click: getClicksAtDate,
      ctr: getCtrAtDate,
      duration: getDurationsAtdate,
    },
  }
  
  const config = {
    x: { type: 'band', paddingInner: 0.3 } as const,
    y: { type: 'linear' } as const,
  };

  return (
    <XYChart
      theme={lightTheme}
      xScale={config.x}
      yScale={config.y}
      height={Math.min(400, height)}
      captureEvents={true}
      // onPointerUp={(d) => {
      //   setAnnotationDataKey(d.key as 'New York' | 'San Francisco' | 'Austin');
      //   setAnnotationDataIndex(d.index);
      // }}
    >
      <MarkerCircle id="marker-circle" fill="#333" size={2} refX={2} />
      <AnimatedGrid
        key={`grid-${animationTrajectory}`} // force animate on update
        columns={false}
        rows={true}
        animationTrajectory={animationTrajectory}
        numTicks={numTicks}
      />
      <AnimatedLineSeries
        dataKey={dataKey}
        data={data}
        xAccessor={accessors.x}
        yAccessor={accessors.y[dataKey]}
        curve={curveLinear}
        markerStart="url(#marker-circle)"
        markerMid="url(#marker-circle)"
        markerEnd="url(#marker-circle)"
      />
      <AnimatedAxis
        key={`time-axis`}
        orientation={'bottom'}
        numTicks={numTicks}
      />
      <AnimatedAxis
        key={`temp-axis`}
        orientation={'left'}
        numTicks={numTicks}
        // values don't make sense in stream graph
        // tickFormat={stackOffset === 'wiggle' ? () => '' : undefined}
      />
      <Tooltip<CityTemperature>
        showHorizontalCrosshair={true}
        showVerticalCrosshair={true}
        snapTooltipToDatumX={true}
        snapTooltipToDatumY={true}
        // showDatumGlyph={(snapTooltipToDatumX || snapTooltipToDatumY) && !renderBarGroup}
        // showSeriesGlyphs={sharedTooltip && !renderBarGroup}
        // renderGlyph={enableTooltipGlyph ? renderTooltipGlyph : undefined}
        renderTooltip={({ tooltipData, colorScale }) => (
          <>
            {/** date */}
            {(tooltipData?.nearestDatum?.datum &&
              accessors.x(tooltipData?.nearestDatum?.datum)) ||
              'No date'}
            <br />
            <br />
            {/** temperatures */}
            {(
              ([tooltipData?.nearestDatum?.key]).filter((date) => date)
            ).map((date) => {
              const value =
                tooltipData?.nearestDatum?.datum &&
                accessors['y'][date](
                  tooltipData?.nearestDatum?.datum,
                );

              return (
                <div key={date}>
                  {value == null || Number.isNaN(value)
                    ? '–'
                    : value}
                  {' '}
                  <em
                    style={{
                      color: colorScale?.(date),
                      textDecoration:
                        tooltipData?.nearestDatum?.key === date ? 'underline' : undefined,
                    }}
                  >
                    {date}
                  </em>
                </div>
              );
            })}
          </>
        )}
      />
    </XYChart>
  )
}

const modifiers = [
  {
    name: "offset",
    enabled: true,
    options: {
      offset: [10, 10]
    }
  }
]

const TooltipComp = ({ info, color = '', top = 3 }) => {
  const [shouldShow, setShouldShow] = useState(false);

  return (
    <Manager>
      <Reference>
        {({ ref }) => (
          <Box
            ref={ref}
            style={{ position: 'relative', top: top, left: 6 }}
            color={color}
          >
            <BsInfoCircle
              type="button"
              onMouseEnter={() => {
                setShouldShow(true);
              }}
              onMouseLeave={() => {
                setShouldShow(false);
              }}
            />
          </Box>
        )}
      </Reference>
      {shouldShow ? (
        <Popper
          placement="top"
          modifiers={modifiers}
        >
          {({ ref, style, placement, arrowProps }) => (
            <Card variant="tooltip" ref={ref} style={style} data-placement={placement}>
              {info}
              <div ref={arrowProps.ref} style={arrowProps.style} />
            </Card>
          )}
        </Popper>
      ) : null}
  </Manager>
  )
}

const Analytics = () => {
  const [country, setCountry] = useState(true);
  // ?metrics=all&page_id=1&brand_id=1&start_at=2021-12-10&end_at=2021-12-14
  const paramConfigMap = {
    metrics: StringParam,
    page_id: NumberParam,
    brand_id: NumberParam,
    start_at: DateParam,
    end_at: DateParam,
    start_at_vt: DateParam, // Start at for visitor time
    end_at_vt: DateParam, // End at for visitor time
  };

  const [query, setQuery] = useQueryParams(paramConfigMap);
  const [key, setKey] = useState('visitor');
  const [showDate, setShowDate] = useState(false);
  const [showStartDate, setShowStartDate] = useState(false);
  const [showEndDate, setShowEndDate] = useState(false);
  const startAt = dayjs(query.start_at).format('DD MMM YYYY');
  const endAt = dayjs(query.end_at).format('DD MMM YYYY');
  const difference = dayjs(endAt).diff(startAt, 'day');
  const today = dayjs(new Date()).format('MMM YYYY')
  const [timeSelected, setTimeSelected] = useState(false);

  const { isLoading, isSuccess, error, data, isFetching } = useQuery(
    [`metrics`, stringify(encodeQueryParams(paramConfigMap, (({
      start_at_vt, //removed
      end_at_vt, //removed
      ...rest
    }) => ({...rest}))(query)))],
    ({ queryKey }) => api.getMetrics(queryKey[1]),
    {
      enabled: !!(query.brand_id && !!query.end_at && query.metrics && query.page_id && !!query.start_at),
    }
  );

  const { data: dataVisitorTime } = useQuery(
    [`metrics`, stringify(encodeQueryParams(paramConfigMap, {
      ...query,
      start_at: query.start_at_vt,
      end_at: query.end_at_vt,
    }))],
    ({ queryKey }) => api.getMetrics(queryKey[1]),
    {
      enabled: !!(query.brand_id && !!query.end_at_vt && query.metrics && query.page_id && !!query.start_at_vt),
    }
  );

  const trafficVisitorData = dataVisitorTime?.data?.traffic_visitor || []
  const dataMax = Math.max(...trafficVisitorData.map(val => val.value));
  const dataMin = Math.min(...trafficVisitorData.map(val => val.value));
  const max = trafficVisitorData.filter(val => val.value === (dataMax));
  const min = trafficVisitorData.filter(val => val.value === (dataMin));

  const { isSuccess: isPageSuccess, data: pages } = useQuery(
    `pages`,
    api.pages
  ,{
    onSuccess: (data) => {
      const sevenDaysAgo = xDaysAgo(7);
      const brandId = data?.data.length !== 0 && data?.data[0].brand.id;
      const pageId = data?.data.length !== 0 && data?.data.filter(page => page.state === 'published')[0].id;

      pageId && brandId && setQuery((latestQuery) => ({
        brand_id: latestQuery.brand_id || brandId,
        end_at: latestQuery.end_at || sevenDaysAgo.today,
        metrics: 'all',
        page_id: latestQuery.page_id || pageId,
        start_at: latestQuery.start_at || sevenDaysAgo.xDaysAgo,
        start_at_vt: latestQuery.start_at_vt || sevenDaysAgo.xDaysAgo,
        end_at_vt: latestQuery.end_at_vt || sevenDaysAgo.today
      }))
    }
  });

  // useEffect(() => {
  //   const sevenDaysAgo = xDaysAgo(7);

  //   setQuery((latestQuery) => ({
  //     brand_id: latestQuery.brand_id || 1,
  //     end_at: latestQuery.end_at || sevenDaysAgo.xDaysAgo,
  //     metrics: 'all',
  //     page_id: latestQuery.page_id || 1,
  //     start_at: latestQuery.start_at || sevenDaysAgo.today,
  //   }))
  // }, [isPageSuccess])


  return (
    isSuccess && <ApplicationShell>
      <Composition
        width={'100%'}
        inline
        areas={areas}
        templateCols={`3fr 1fr`}
        templateRows={`auto 660px 423px 423px 423px`}
        gap={20}
        padding={32}
      >
        {({
            Header: HeaderWrapper,
            Activity: ActivityWrapper,
            Links: LinksWrapper,
            Time: TimeWrapper,
            Origins: OriginsWrapper,
            Locations: LocationsWrapper,
            Socials: SocialsWrapper,
            Devices: DevicesWrapper,
          }) => (
          <>
            <HeaderWrapper>
              <Box>
                <Text variant="headline" color="#B9A511">Analytics</Text>
              </Box>
              <Box>
                <Text variant="body">Pantau insight tentang pengunjung dan performance page</Text>        
              </Box>
            </HeaderWrapper>
            <ActivityWrapper>
              <Card
                variant='sheet'
                sx={{height: '100%' }}
              >
                <Box mb={4}>
                  <Flex sx={{ justifyContent: 'space-between'}}>
                    <Flex>
                      <h3 sx={{ margin: '0' }}>Aktivitas Page</h3>
                      <TooltipComp info="Performa landing page berdasarkan periode yang dipilih" color="#828282" top={5} />
                    </Flex>
                    <Flex
                      sx={{
                        position: 'relative'
                      }}
                    >
                    {showDate && (
                      <>
                        <Flex
                          sx={{
                            minWidth: 148,
                            height: 35,
                            maxWidth: '30%',
                            border: '1px solid #D1D5DB',
                            borderRadius: 8,
                            fontSize: 14,
                            position: 'relative',
                            top: 10,
                            right: 13,
                            alignItems: 'center',
                            justifyContent: 'center',
                            mr: 3
                          }}
                          onClick={() => setShowStartDate(true)}
                        >
                          {startAt || 'Tanggal awal'}
                        </Flex>
                        {showStartDate && (
                          <Box sx={{ zIndex: 1, position: 'absolute', right: 2, top: 5 }}>
                            <Calendar
                              onChange={(val) => {
                                setShowStartDate(false);
                                setQuery({
                                  ...query,
                                  start_at: val
                                })
                              }}
                              value={query.start_at}
                              maxDate={query.end_at}
                            />
                          </Box>
                        )}
                        <Flex
                          sx={{
                            minWidth: 148,
                            height: 35,
                            maxWidth: '30%',
                            border: '1px solid #D1D5DB',
                            borderRadius: 8,
                            fontSize: 14,
                            position: 'relative',
                            top: 10,
                            right: 13,
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                          onClick={() => setShowEndDate(true)}
                        >
                          {endAt || 'Tanggal akhir'}
                        </Flex>
                        {showEndDate && (
                          <Box sx={{ zIndex: 1, position: 'absolute', right: 2, top: 5 }}>
                            <Calendar
                              onChange={(val) => {
                                setShowEndDate(false);
                                setQuery({
                                  ...query,
                                  end_at: val
                                })
                              }}
                              value={query.end_at}
                              maxDate={new Date()}
                              minDate={query.start_at}
                            />
                          </Box>
                        )}
                      </>
                      )}
                      <Selection
                        sx={borderStyle}
                        variant="select"
                        onChange={(e) => {
                          if (e.target.value === '0') {
                            setShowDate(true);
                          } else {
                            const selectedDaysAgo = xDaysAgo(parseInt(e.target.value));
                            setShowDate(false);
                            setShowStartDate(false);
                            setShowEndDate(false);
                            setQuery((latestQuery) => ({
                              ...latestQuery,
                              end_at: selectedDaysAgo.today,
                              start_at: selectedDaysAgo.xDaysAgo,
                            }))
                          }
                        }}
                        value={showDate ? 0 : difference}
                      >
                        {opts?.map(opt => (
                          <option value={opt.value}>{opt.label}</option>
                          ))}
                      </Selection>
                    </Flex>
                  </Flex>
                  <span>Data dari {startAt} - {endAt}</span>
                </Box>
                <Flex>
                  <Box sx={{pr: 3}}>
                    <Card variant={['visitor'].includes(key) ? "selectedActivity" : 'activity'} onClick={() => setKey('visitor')}>
                      <Flex color="#828282">
                        <span>Total Pengunjung</span>
                        <TooltipComp info="Jumlah pengunjung yang melihat landing page" />
                      </Flex>
                      <Box sx={{ fontWeight: 'bold', fontSize: '23px' }}>
                        {data?.data?.total_visitors}
                      </Box>
                    </Card>
                  </Box>
                  <Box sx={{pr: 3}}>
                    <Card variant={['click'].includes(key) ? "selectedActivity" : 'activity'} onClick={() => setKey('click')}>
                      <Flex color="#828282">
                        <span>Total Klik</span>
                        <TooltipComp info="Jumlah klik link pada landing page" /> 
                      </Flex>
                      <Box sx={{ fontWeight: 'bold', fontSize: '23px' }}>
                        {data?.data?.total_clicks}
                      </Box>
                    </Card>
                  </Box>
                  <Box sx={{pr: 3}}>
                    <Card variant={['ctr'].includes(key) ? "selectedActivity" : 'activity'} onClick={() => setKey('ctr')}>
                      <Flex color="#828282">
                        <span>CTR</span>
                        <TooltipComp info="Total Klik / Total Pengunjung" /> 
                      </Flex>
                      <Box sx={{ fontWeight: 'bold', fontSize: '23px' }}>
                        {`${data?.data?.total_ctr} %`}
                      </Box>
                    </Card>
                  </Box>
                  <Box sx={{pr: 3}}>
                    <Card variant={['duration'].includes(key) ? "selectedActivity" : 'activity'} onClick={() => setKey('duration')}>
                      <Flex color="#828282">
                        <span>Durasi rata - rata sebelum klik</span>
                        <TooltipComp info="Jumlah durasi semua pengunjung sebelum mengklik link / Total pengunjung" /> 
                      </Flex>
                      <Box sx={{ fontWeight: 'bold', fontSize: '23px' }}>
                        {data?.data?.average_duration}
                      </Box>
                    </Card>
                  </Box>
                </Flex>
                <ParentSize>
                  {({ width, height }) => <LineChart dataKey={key}
                  
                  data={data?.data?.visitor_per_day.map((dailyVisitor, i) => ({
                    date: Object.keys(dailyVisitor)[0],
                    visitor: dailyVisitor[Object.keys(dailyVisitor)[0]],
                    click: data?.data?.clicks_per_day[i][Object.keys(dailyVisitor)[0]],
                    ctr: data?.data?.ctr_per_day[i][Object.keys(dailyVisitor)[0]],
                    duration: data?.data?.average_duration_per_day[i][Object.keys(dailyVisitor)[0]],
                  }))}
                  width={width} height={height} />}
                </ParentSize>
                {/* <LineChart width={'100%'} height={'100%'} data={data?.data?.visitor_per_day} /> */}
              </Card>
            </ActivityWrapper>
            <LinksWrapper>
              <Card
                sx={{
                  height: '-webkit-fill-available'
                }}
              >
                <Flex mb={2}>
                  <h3 sx={{ mt: 0 }}>Link sering diklik</h3>
                  <TooltipComp info="Link paling banyak diklik pada landing page" color="#828282" top={4} />
                </Flex>
                <Box
                  sx={{
                    overflow: 'scroll',
                    height: '-webkit-fill-available'
                  }}
                >
                  <Table 
                    columns={LinkColumns}
                    data={data?.data?.frequently_clicked || []}
                    makeOnClick={() => console.log('cek')}
                    isAnalytic
                    disablePagination
                  />
                </Box>
              </Card>
            </LinksWrapper>
            <TimeWrapper>
              <Card
                variant='sheet'
                sx={{ position: 'relative' }}
              >
                <Flex mt={3} mb={4} sx={{ justifyContent: 'space-between' }}>
                  <Flex sx={{ alignItems: 'center' }}>
                    <h3 sx={{ margin: '0' }}>Waktu</h3>
                    <TooltipComp info="Waktu kunjung dalam durasi 1 jam yang memiliki rata-rata pengunjung paling banyak dan paling sedikit" color="#828282" top={3} />
                  </Flex>
                  <Box sx={{
                    minWidth: 148,
                    minHeight: 40,
                    maxWidth: '30%',
                    border: '1px solid #D1D5DB',
                    borderRadius: 8,
                    fontSize: 14
                  }}>
                    <Flex
                      sx={{ justifyContent: 'space-between', cursor: 'pointer' }}
                      m={10}
                      onClick={() => setTimeSelected(true)}
                    >
                      {/* TODO: Date and icon is not aligned */}
                      {dayjs(query.start_at_vt).format('MMM YYYY')}
                      <VscCalendar fill="#DEDEDE" sx={{ alignItems: 'center' }} />
                    </Flex>
                  </Box>
                </Flex>
                {timeSelected && (
                  <Box sx={{ zIndex: 1, position: 'absolute', right: 2, top: 5 }}>
                    <SCalendar
                      onChange={(date) => {
                        setQuery((latestQuery) => ({
                          ...latestQuery,
                          start_at_vt: dayjs(date).startOf('month').toDate(),
                          end_at_vt: dayjs(date).endOf('month').toDate(),
                        }))
                        setTimeSelected(false)
                      }}
                      value={query.start_at_vt}
                      maxDetail='year'
                      maxDate={
                        new Date()
                      }
                    />
                  </Box>
                )}
                <Card mb={3} variant="time">
                  <Flex sx={{ position: 'relative', top: -20 }}>
                    <Box sx={{ fontSize: 28, fontWeight: 'regular' }}>Jam ramai</Box>
                    <Box ml={3} sx={{ alignSelf: 'center' }}>
                      <BsGraphUp color="#0998FF" />
                    </Box>
                  </Flex>
                  <Grid gap={1} columns={3}>
                    {trafficVisitorData.length > 0 ? max.map(item => (
                      <Box variant="styles.box.timeBox">{item.hour}</Box>
                    )) : (
                      <Box sx={{ textAlign: 'center' }}>-</Box>
                    )}
                  </Grid>
                </Card>
                <Card variant="time">
                  <Flex sx={{ position: 'relative', top: -20 }}>
                    <Box sx={{ fontSize: 28, fontWeight: 'regular' }}>Jam sepi</Box>
                    <Box ml={3} sx={{ alignSelf: 'center' }}>
                      <BsGraphDown color="#F83030" />
                    </Box>
                  </Flex>
                  <Grid gap={1} columns={3}>
                    {trafficVisitorData.length > 1 ? min.map(item => (
                      <Box variant="styles.box.timeBox">{item.hour}</Box>
                    )) : (
                      <Box sx={{ textAlign: 'center' }}>-</Box>
                    )}
                  </Grid>
                </Card>
              </Card>
            </TimeWrapper>
            <OriginsWrapper>
              <Card
                sx={{
                  height: '-webkit-fill-available'
                }}
              >
                <Flex mb={2}>
                  <h3 sx={{ mt: 0 }}>Asal Pengunjung</h3>
                  <TooltipComp info="Platform yang digunakan pengunjung untuk mengakses landing page" color="#828282" top={4} />
                </Flex>
                <Box
                  sx={{
                    overflow: 'scroll',
                    height: '-webkit-fill-available'
                  }}
                >
                  <Table 
                    columns={OriginColumns}
                    data={data?.data?.visitors_origin || []}
                    makeOnClick={() => console.log('cek')}
                    isAnalytic
                    disablePagination
                  />
                </Box>
              </Card>
            </OriginsWrapper>
            <LocationsWrapper>
              <Card
                sx={{
                  height: '-webkit-fill-available'
                }}
              >
                <Flex mb={3}>
                  <h3 sx={{ margin: '0' }}>Lokasi Teratas</h3>
                  <TooltipComp info="Asal pengunjung terbanyak berdasarkan negara dan kota" color="#828282" top={5} />
                </Flex>
                <Flex
                  as="nav"
                  sx={{
                    justifyContent: 'space-around',
                    backgroundColor: 'gray1',
                    padding: '6px 6px',
                    borderRadius: '8px'
                  }}
                >
                  <Box p={2} sx={country ? countryStyle : countryPadding} onClick={() => setCountry(true)}>
                    Negara/Wilayah
                  </Box>
                  <Box p={2} sx={!country ? cityStyle : cityPadding} onClick={() => setCountry(false)}>
                    Kota
                  </Box>
                </Flex>
                <Box
                  mb={4}
                  sx={{
                    overflow: 'scroll',
                    height: '-webkit-fill-available'
                  }}
                >
                  {country ? (
                    <Table 
                      columns={CountryColumns}
                      data={data?.data?.top_visitor_by_country || []}
                      makeOnClick={() => console.log('cek')}
                      isAnalytic
                      disablePagination
                    />
                  ) : (
                    <Table 
                      columns={CityColumns}
                      data={data?.data?.top_visitor_by_city || []}
                      makeOnClick={() => console.log('cek')}
                      isAnalytic
                      disablePagination
                    />
                  )}
                </Box>
              </Card>
            </LocationsWrapper>
            <SocialsWrapper>
              <Card
                sx={{
                  height: '-webkit-fill-available'
                }}
              >
                <Flex mb={2}>
                  <h3 sx={{ mt: 0 }}>Media Sosial yang sering diklik</h3>
                  <TooltipComp info="Media sosial paling banyak diklik pada landing page" color="#828282" top={5} />
                </Flex>
                <Box
                  sx={{
                    overflow: 'scroll',
                    height: '-webkit-fill-available'
                  }}
                >
                  <Table 
                    columns={Sosmedcolumns}
                    data={data?.data?.frequently_clicked_sm || []}
                    makeOnClick={() => console.log('cek')}
                    isAnalytic
                    disablePagination
                  />
                </Box>
              </Card>
            </SocialsWrapper>
            <DevicesWrapper>
              <Card
                sx={{
                  height: '-webkit-fill-available'
                }}
              >
                <Flex mb={2}>
                  <h3 sx={{ mt: 0 }}>Device Teratas</h3>
                  <TooltipComp info="Jenis device yang paling banyak digunakan pengunjung untuk mengakses landing page" color="#828282" top={5} />
                </Flex>
                <Box
                  sx={{
                    overflow: 'scroll',
                    height: '-webkit-fill-available'
                  }}
                >
                  <Table 
                    columns={DeviceColumns}
                    data={data?.data?.top_visitor_by_device_type || []}
                    makeOnClick={() => console.log('cek')}
                    isAnalytic
                    disablePagination
                  />
                </Box>
              </Card>
            </DevicesWrapper>
          </>
        )}
      </Composition>
    </ApplicationShell>
  )
}

const SCalendar = styled(Calendar)`
  .react-calendar { 
    width: 400px;
    max-width: 100%;
    background-color: #fff;
    color: #222;
    border-radius: 8px;
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
    }
  .react-calendar__navigation button {
    color: black;
    min-width: 44px;
    background: none;
    font-size: 16px;
    margin-top: 8px;
  }
  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background-color: #f8f8fa;
  }
  .react-calendar__navigation button[disabled] {
    background-color: #f0f0f0;
  }
  abbr[title] {
    text-decoration: none;
  }

  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background: #f8f8fa;
    color: #B9A511;
    border-radius: 6px;
  }
  .react-calendar__tile--now {
    background: #f0e69999;
    border-radius: 6px;
    font-weight: bold;
    color: #757575;
  }
  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus {
    background: #f0e69999;
    border-radius: 6px;
    font-weight: bold;
    color: #757575;
  }
  .react-calendar__tile--hasActive:enabled:hover,
  .react-calendar__tile--hasActive:enabled:focus {
    background: #f8f8fa;
  }
  .react-calendar__tile--active {
    background: #B9A511;
    border-radius: 6px;
    font-weight: bold;
    color: white;
  }
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: #B9A511;
    color: white;
  }
  .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #f8f8fa;
  }
  .react-calendar__tile--range {
    background: #f8f8fa;
    color: #B9A511;
    border-radius: 0;
  }
  .react-calendar__tile--rangeStart {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    background: #B9A511;
    color: white;
  }
  .react-calendar__tile--rangeEnd {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    background: #B9A511;
    color: white;
  }
`;

export default Analytics;