/**@jsx jsx */
import React, { useState } from 'react'
import { Composition } from 'atomic-layout';
import lz from "lzutf8";
import { Box, Button, Flex, IconButton, jsx, Text, } from 'theme-ui'
import { MdOutlineImage, MdOutlineList } from 'react-icons/md'
import { useParams } from "@reach/router"
import { Container } from '@theme-ui/components'
import { FaChevronLeft, FaRedo, FaUndo } from 'react-icons/fa'
import { Element, Frame, useEditor, ROOT_NODE } from '@craftjs/core'
import { useMutation, useQuery } from 'react-query'
import { navigate } from 'gatsby'
import HypefastLogo from "../../assets/hypefast.svg"
import Toolbox from '../../components/Editor/Toolbox';
import RootContainer, { PhoneFrame } from '../../components/UserComponents/RootContainer';
import Sidebar from '../../components/Layout/Sidebar';
import api from '../../utils/api';
import ScrolledDiv from '../../components/ScrolledDiv';
import Toast from '../../components/Editor/Toast';
import useOnclickOutside from "react-cool-onclickoutside";

const areas = `
  header header header
  sidebar subsidebar content
`;

const Layout = (props) => {
  const [activeMenu, setActiveMenu] = useState<any>({
    menu: 'Bagian',
    active: true,
  })

  const { pageUrl } = useParams();
  const [showModal, setShowModal] = useState(false);
  const ref = useOnclickOutside(() => setShowModal(false))
  const [json, setJson] = useState(null);
  const { canUndo, canRedo, actions, query: { serialize }, layerComponent, settingsComponent } = useEditor((state, query) => ({
    canUndo: query.history.canUndo(),
    canRedo: query.history.canRedo(),
    layerComponent: 
      state.nodes[ROOT_NODE] &&
        state.nodes[ROOT_NODE].related.layer,
    settingsComponent:
        state.nodes[ROOT_NODE] &&
        state.nodes[ROOT_NODE].related.settings,
  }));

  const {
    isLoading: isLoadingUpdatePage,
    mutate: mutateUpdatePage,
  } = useMutation(
    'update-page',
    (content: string) => api.updatePage({
      content,
      pageUrl,
    }),
    {
      onSuccess: ({ data }) => {
        navigate('/page/dashboard');
        Toast({
          title: 'Update Berhasil',
          message: 'Landing page berhasil diupdate',
          type: 'success',
        });
      }
    },
  );

  const {
    isLoading: isLoadingCreatePage,
    mutate: mutateCreatePage,
  } = useMutation(
    'create-page',
    (content: string) => api.createPage({
      content,
    }),
    {
      onSuccess: ({ data }) => {
        navigate('/page/dashboard');
        Toast({
          title: 'Publikasi Berhasil',
          message: 'Landing page berhasil dipublikasikan',
          type: 'success',
        });
      }
    },
  );

  useQuery(
    `page-${pageUrl}`,
    () => api.page(pageUrl),
    {
      onSuccess: ({ data }) => {
        const json = lz.decompress(lz.decodeBase64(data?.content));
        setJson(json);
      },
      enabled: !!pageUrl && pageUrl !== 'new',
    }
  );

  return (
    <Composition
      width={'100%'}
      height={'100vh'}
      inline
      areas={areas}
      templateRows={`1fr 12fr`}
      templateCols={'1fr 3fr 6fr'}
    >
      {({ Header: HeaderWrapper, Sidebar: SidebarWrapper, Subsidebar: SubsidebarWrapper, Content: ContentWrapper }) => (
        <>
          <HeaderWrapper>
            <Flex
              p={2}
              sx={{
                alignItems: 'center',
                height: '100%',
                justifyContent: 'space-between',
                borderBottomStyle: 'solid',
                borderBottomColor: 'sunken',
                borderBottomWidth: 1,
              }}
            >
              <Box>
                <HypefastLogo
                  onClick={() => {
                    navigate('/page/dashboard');
                  }}
                  width={'100%'}
                  cursor="pointer"
                />
              </Box>
              <Box>
                <Button
                  variant='iconBtn'
                  onClick={() => setShowModal(!showModal)}
                  sx={{ width: '80px', ml: 'auto', border: 'unset', marginRight: '20px' }}
                >
                  <Box as={FaChevronLeft} sx={{ mb: '-2px', mr: 1 }} />
                  <Text>Kembali</Text>
                </Button>
                <IconButton
                  sx={{

                  }}
                  disabled={!canUndo}
                  onClick={() => {
                    actions.history.undo();
                  }}
                >
                  <FaUndo></FaUndo>
                </IconButton>
                <IconButton
                  disabled={!canRedo}
                  onClick={() => {
                    actions.history.redo();
                  }}
                >
                  <FaRedo></FaRedo>
                </IconButton>
                <Button
                  disabled={isLoadingCreatePage || isLoadingUpdatePage}
                  onClick={() => {
                    if (pageUrl === 'new') {
                      mutateCreatePage(
                        lz.encodeBase64(lz.compress(serialize()))
                      )
                    }
                    else {
                      mutateUpdatePage(
                        lz.encodeBase64(lz.compress(serialize()))
                      )
                    }
                  }}
                >
                  <Text>
                    Publikasi
                  </Text>
                </Button>
              </Box>
              {
                showModal && (
                  <Box
                    onClick={(e) => e.stopPropagation()}
                    bg="gray2"
                    sx={{
                      zIndex: '1055',
                      position: 'fixed',
                      left: '550px',
                      top: '250px',
                      borderRadius: 8,
                      width: '30%',
                      textAlign: 'justify'
                    }}
                    ref={ref}
                  >
                    <Box px={3} bg="gray2">
                      <h3>Konfirmasi</h3>
                      <Text>Apakah Anda Yakin Akan Kembali? Perubahan Anda tidak tersimpan</Text>
                    </Box>
                    <Flex mt={3} sx={{ justifyContent: 'end', borderRadius: '0px 0px 8px 8px' }} bg="gray1" py={3} pr={2}>
                      <Button
                        mr={2}
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowModal(false);
                        }}
                      >
                        Tidak
                      </Button>
                      <Button
                        variant="error"
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowModal(false);
                          navigate('/page/dashboard');
                        }}
                      >
                        Ya
                      </Button>
                    </Flex>
                  </Box>
                )
              }
            </Flex>
          </HeaderWrapper>
          <SidebarWrapper>
            {layerComponent && React.createElement(layerComponent)}
          </SidebarWrapper>
          <SubsidebarWrapper
            as={ScrolledDiv}
          >
            {settingsComponent && React.createElement(settingsComponent)}
          </SubsidebarWrapper>
          <ContentWrapper
            justify='center'
            padding={32}
            as={ScrolledDiv}
          >
            <PhoneFrame>
              {json &&
                <Frame key={'edit'} data={json}>
                  <Element is={RootContainer} canvas></Element>
                </Frame>
                ||
                <Frame key={'new'}>
                  <Element is={RootContainer} canvas></Element>
                </Frame>
              }
            </PhoneFrame>
          </ContentWrapper>
        </>
      )}
    </Composition>
  )
  
  return (
    <div
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        variant: 'layout.root',
      }}
    >
      <header
        sx={{
          width: '100%',
          variant: 'layout.header',
          borderBottom: '1px solid darkgrey',
          p: 10,
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bg: 'white',
          zIndex: 300,
        }}
      >
        {/* <Container>Header</Container> */}
        
      </header>
      {/* <footer
        sx={{
          width: '100%',
          variant: 'layout.footer',
        }}>
        <Container>Footer</Container>
      </footer> */}
    </div>
  )
}

export default Layout
