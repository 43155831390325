import React, { useState, FC, memo } from 'react';
// import { Button as BsButton, ButtonProps as BsButtonProps, Spinner } from 'react-bootstrap';
import { Box, Button as ThemeUIButton, ButtonProps as ThemeUIButtonProps, Card, Text, } from 'theme-ui';
import { IconType } from 'react-icons/lib';
import { usePopper } from 'react-popper';
import useOnclickOutside from "react-cool-onclickoutside";
import Button from './Button';


enum Variant {
  PRIMARY,
  SECONDARY
};

interface ButtonProps extends ThemeUIButtonProps {
  full?: boolean;
  isLoading?: boolean;
  rightIcon?: React.ReactElement<IconType>;
  leftIcon?: React.ReactElement<IconType>;
  variant?: 'primary' | 'secondary' | 'outline';
  onLogout: any;
}


const ProfileButton: FC<ButtonProps> = ({
  full = false, isLoading, children, leftIcon, rightIcon, className, disabled, variant, sx, onLogout, ...props
}) => {
  const [showPopper, setShowPopper] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const closePopper = () => {
    setShowPopper(false);
  }

  const ref = useOnclickOutside(() => {
    closePopper();
  })
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      {
        name: 'arrow',
        options: {
          element: arrowElement
        }
      },
      {
        name: "offset",
        enabled: true,
        options: {
          offset: [0, 10]
        }
      },
    ],
    placement: 'bottom-start',
  });

  return (
    <Box ref={ref}>
      <ThemeUIButton
        sx={{
          width: full ? '100%': 'auto',
          ...sx,
        }}
        disabled={isLoading || disabled}
        variant={variant}
        ref={setReferenceElement}
        onClick={() => {
          setShowPopper(!showPopper);
        }}
        {...props}
      >
        {children}
      </ThemeUIButton>
      {/* <Button variant='primary' > */}
      {/* <button type="button" ref={setReferenceElement}> */}
        {/* Reference element */}
      {/* </Button> */}
      {/* </button> */}

      <div ref={setPopperElement} style={styles.popper} {...attributes.popper}>
        {
          showPopper && (
            <Box
              sx={{
                backgroundColor: 'white',
                width: 200,
                py: 2,
                px: 3,
                borderRadius: 'small',
                boxShadow: '0 0 8px 0 rgba(0, 0, 0, 0.14)',
                display: 'flex',
                flexDirection: 'column'
              }}
              onClick={onLogout}
            >
              <Text
                variant='logout'
              >
                Logout
              </Text>
            </Box>
          )
        }
        <div ref={setArrowElement} style={styles.arrow} />
      </div>
    </Box>
  );
  
}

export default memo(ProfileButton);
