import React from 'react';
import lz from "lzutf8";
import { Editor, Element, Frame } from '@craftjs/core'
import Text from '../components/UserComponents/Text'
import Video from '../components/UserComponents/Video'
import SocialIcons from '../components/UserComponents/SocialIcons'
import SocialIcon from '../components/UserComponents/SocialIcon'
import Links from '../components/UserComponents/Links'
import Link from '../components/UserComponents/Link'
import Image from '../components/UserComponents/Image'
import Divider from '../components/UserComponents/Divider'
import Products from '../components/UserComponents/Products'
import Product from '../components/UserComponents/Product'
import Promo from '../components/UserComponents/Promo'
import RootContainer from '../components/UserComponents/RootContainer';


const Microsite = ({ content }) => {
  return (
    <Editor
      enabled={false}
      resolver={{
        RootContainer, Text, Video, SocialIcons, SocialIcon, Link, Links, Image, Divider, Products, Product, Promo
      }}
    >
      {content &&
        <Frame key={'show'} data={lz.decompress(lz.decodeBase64(content))}>
          <Element is={RootContainer} canvas></Element>
        </Frame>
      }
    </Editor>
  )
}

export default Microsite;