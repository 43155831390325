/**@jsx jsx */
import React, { useState } from "react";
import { Composition } from 'atomic-layout';
import { Box, Card, Container, Flex, IconButton, Image, Text, Input } from '@theme-ui/components';
import { MdEdit, MdOutlineRemoveRedEye } from "react-icons/md";
import { FaEllipsisV, FaPlus, FaShare } from "react-icons/fa";
import { jsx, Spinner } from 'theme-ui'
import { QueryClientProvider, useQuery } from "react-query";
import { navigate } from "gatsby";
import PageCards from "./compositions/Content/PageCards";
import api from '../../utils/api';
import ApplicationShell from "../../components/Layout/ApplicationShell";
import { Table } from "../../components/Table";
import Button from "../../components/Button";

const columns = [
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: 'Brand',
    accessor: 'brand.name',
  },
];

const areas = `
  header
  content
`;

const UsersPage = () => {
  const { isLoading, error, data, isFetching } = useQuery(
    `users`,
    api.listUsers,
  );


  return (
    <ApplicationShell>
      <Composition
        width={'100%'}
        inline
        areas={areas}
        templateRows={`1fr 12fr`}
        gap={20}
        padding={32}
      >
        {({ Header: HeaderWrapper, Content: ContentWrapper }) => (
          <>
            <HeaderWrapper>
              <Flex
                sx={{
                  flex: 1,
                  flexDirection: 'row',
                  justifyContent: 'space-between'
                }}
              >
                <Box>
                  <Box>
                    <Text variant="headline" color="#B9A511">Users</Text>
                  </Box>
                </Box>
                <Button
                  onClick={() => {
                    navigate('/page/users/new')
                  }}
                  style={{
                    height: 'fit-content'
                  }}
                >
                  <Text variant="body">Tambah User Baru</Text>        
                </Button>
              </Flex>
            </HeaderWrapper>
            <ContentWrapper>
              <>
                {/* <Card
                  variant='sheet'
                > */}
                  {
                    (isLoading && <Spinner/>)
                      ||
                      <Table
                        makeOnClick={(id) => (e) => navigate(`/page/users/${id}`)}
                        columns={columns}
                        data={data.data}
                        initialState={{
                          sortBy: [
                            {
                                id: 'name',
                                desc: false
                            }
                          ]
                        }}
                        isSearcheable
                      />
                  }
                {/* </Card> */}
              </>
            </ContentWrapper>
          </>
        )}
      </Composition>
    </ApplicationShell>
  )
}

export default UsersPage;