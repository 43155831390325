/** @jsx jsx */

import React, { useState } from 'react'
import { Box, Button, Card, Flex, Input, jsx, Text, Select } from 'theme-ui'
import {
  useFilters,
  UseFiltersOptions,
  UseTableInstanceProps,
  PluginHook,
  useTable,
  UseTableOptions,
  usePagination,
  UsePaginationOptions,
  useSortBy,
  useGlobalFilter,
  useAsyncDebounce,
} from 'react-table'
import {
  HiOutlineArrowNarrowLeft,
  HiOutlineArrowNarrowRight,
} from 'react-icons/hi'
import { navigate } from 'gatsby'
import  { TiArrowSortedDown, TiArrowSortedUp } from 'react-icons/ti'

interface Options extends UseTableOptions<{}>, UsePaginationOptions<{}> {}
export interface TableProps {
  options: Options
  plugins?: PluginHook<{}>[]
  totalData?: number
  onChangePage?: () => {}
  disablePagination?: boolean
  keyExtractor?: (item: any) => string
  isAnalytic?: boolean
}

// options={{
//   initialState: {
//     pageSize: 10,
//   },
//   columns: columns,
//   data: dataReports,
// }}
// totalData={dataApi?.data?.total}

export const Table: React.FC<TableProps> = ({
  columns,
  data,
  makeOnClick,
  initialState,
  options,
  plugins,
  totalData,
  disablePagination,
  isSearcheable,
  isAnalytic = false,
  keyExtractor,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setFilter,
    state: { pageIndex, pageSize },
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
  } = useTable(
    {
      data,
      columns,
      initialState,
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination,
  )

  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200);

  return (
    <Box>
      {isSearcheable && (
        <Input
          value={value || ""}
          placeholder="Search"
          onChange={e => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          variant="search"
        />
      )}
      {data.length > 0 ? (
        <table sx={{ variant: isAnalytic ? 'styles.analitycTable' : 'styles.table' }} {...getTableProps()} style={{ backgroundColor: 'white' }}>
          <thead sx={{ variant: 'styles.thead' }}>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    sx={{
                      variant: 'styles.th',
                      width: column.width,
                      minWidth: column.minWidth,
                      maxWidth: column.maxWidth,
                      whiteSpace: ['nowrap', null],
                    }}
                  >
                    <Text>
                      {column.render('Header')}
                    </Text>
                    <span style={{ verticalAlign: 'middle', marginLeft: '4px' }}>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? <TiArrowSortedDown />
                          : <TiArrowSortedUp />
                        : ''}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row)
              return (
                <tr
                  sx={{
                    variant: 'styles.tr',
                    bg: 'inherit',
                  }}
                  {...row.getRowProps()} onClick={makeOnClick(row.original.id)}
                >
                  {row.cells.map(cell => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        sx={{ variant: 'styles.td' }}
                      >
                        <Text>
                          {cell.render('Cell')}
                        </Text>
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      ) : (
        <Box sx={{ textAlign: 'center' }}>Tidak ada data</Box>
      )}
      {!disablePagination && (
        <Flex
          sx={{
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Text>
            {page.length === 0 ?
              `No results` :
              `Showing ${pageIndex + 1} - ${page.length} of ${data.length} results`}
          </Text>
          {page.length !== 0 && 
            <Box>  
              <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                {'<<'}
              </button>{' '}
              <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                {'<'}
              </button>{' '}
              <Text>
                {`${pageIndex + 1}/${pageCount}`}
              </Text>
              <button onClick={() => nextPage()} disabled={!canNextPage}>
                {'>'}
              </button>{' '}
              <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                {'>>'}
              </button>{' '}
            </Box>
          }
          {/* <Box sx={{display: 'flex'}}>
            <span sx={{ mr: '4px' }}>Result per page</span>
            <Select
              value={pageSize}
              onChange={e => {
                setPageSize(Number(e.target.value))
              }}
            >
              <option value="5">5</option>
              <option value="10">10</option>
            </Select>
          </Box> */}
        </Flex>
      )}
    </Box>
  )
}

Table.defaultProps = {
  plugins: [],
}


// export const Table: React.FC<TableProps> = ({
//   options,
//   plugins,
//   totalData,
//   disablePagination,
//   keyExtractor,
// }) => {
//   const tableInstance = useTable(options, usePagination, ...(plugins || []))

//   const {
//     getTableProps,
//     getTableBodyProps,
//     headerGroups,
//     page,
//     nextPage,
//     previousPage,
//     canNextPage,
//     canPreviousPage,
//     pageOptions,
//     state,
//     prepareRow,
//   } = tableInstance

//   const { pageIndex /* pageSize */ } = state
//   // const pages = totalData / pageSize

//   return (
//     <Box sx={{ overflowX: ['scroll', null] }}>
//       <table {...getTableProps()} sx={{ variant: 'styles.table' }}>
//         <thead sx={{ variant: 'styles.thead' }}>
//           {headerGroups.map((headerGroup) => (
//             <tr {...headerGroup.getHeaderGroupProps()}>
//               {headerGroup.headers.map((column) => {
//                 return (
//                   <th
//                     {...column.getHeaderProps()}
//                     sx={{
                      // variant: 'styles.th',
                      // width: column.width,
                      // minWidth: column.minWidth,
                      // maxWidth: column.maxWidth,
                      // whiteSpace: ['nowrap', null],
//                     }}
//                   >
//                     {column.render('Header')}
//                   </th>
//                 )
//               })}
//             </tr>
//           ))}
//         </thead>
//         <tbody {...getTableBodyProps()}>
//           {page.map((row) => {
//             prepareRow(row)
//             return (
//               <tr
//                 {...row.getRowProps()}
//                 key={
//                   keyExtractor?.(row.original) ||
//                   row.original.id ||
//                   row.getRowProps().id ||
//                   row.id
//                 }
//                 sx={{ variant: 'styles.tr' }}
//               >
//                 {row.cells.map((cell) => (
//                   <td {...cell.getCellProps()} sx={{ variant: 'styles.td' }}>
//                     {cell.render('Cell')}
//                   </td>
//                 ))}
//               </tr>
//             )
//           })}
//         </tbody>
//       </table>

//       {!disablePagination && (
//         <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
//           <span sx={{ alignSelf: 'center', mr: '3' }}>
//             Page{' '}
//             <strong>
//               {pageIndex + 1} of {pageOptions.length}
//             </strong>
//           </span>
//           <Button
//             variant="outline"
//             sx={{ mr: '2', borderRadius: 'xl' }}
//             onClick={previousPage}
//             disabled={!canPreviousPage}
//           >
//             <HiOutlineArrowNarrowLeft
//               sx={{ verticalAlign: 'middle', mr: '2' }}
//             />
//             Previous
//           </Button>
//           <Button
//             variant="outline"
//             sx={{ borderRadius: 'xl' }}
//             onClick={nextPage}
//             disabled={!canNextPage}
//           >
//             Next
//             <HiOutlineArrowNarrowRight
//               sx={{ verticalAlign: 'middle', ml: '2' }}
//             />
//           </Button>
//         </Box>
//       )}
//     </Box>
//   )
// }

// Table.defaultProps = {
//   plugins: [],
// }
