import Toast from "../components/Editor/Toast";

export default function copyToClipboard(text, notice = 'Copied') {
  if (typeof window === 'undefined') return;
  if (typeof document === 'undefined') return;

  if ((window as any)?.clipboardData?.setData) {
    // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
    return (window as any).clipboardData.setData('Text', text);
  } else if (
    document.queryCommandSupported &&
    document.queryCommandSupported('copy')
  ) {
    const textarea = document.createElement('textarea');
    textarea.textContent = text;
    textarea.style.position = 'fixed'; // Prevent scrolling to bottom of page in Microsoft Edge.
    document.body.appendChild(textarea);
    textarea.select();
    try {
      document.execCommand('copy'); // Security exception may be thrown by some browsers.
      Toast({
        message: notice
      });
    } catch (ex) {
      console.warn('Copy to clipboard failed.', ex);
      return false;
    } finally {
      document.body.removeChild(textarea);
    }
  }
}