/**@jsx jsx */
import React, { useEffect, useState } from "react";
import { useParams } from "@reach/router"
import { Composition } from 'atomic-layout';
import { Box, Card, Button, Container, Flex, IconButton, Image, Text } from '@theme-ui/components';
import MyButton from '../../components/Button';
import { useForm } from 'react-hook-form';
import { MdEdit, MdOutlineRemoveRedEye } from "react-icons/md";
import { FaEllipsisV, FaPlus, FaShare } from "react-icons/fa";
import { Input, jsx, Label, Spinner } from 'theme-ui'
import { QueryClientProvider, useMutation, useQuery } from "react-query";
import { navigate } from "gatsby";
import PageCards from "./compositions/Content/PageCards";
import api from '../../utils/api';
import ApplicationShell from "../../components/Layout/ApplicationShell";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Selection from "../../components/Editor/Selection";
import brandAPI from '../../utils/api/brand';
import Toast from "../../components/Editor/Toast";

interface UserData {
  email?: string;
}

const schema: Yup.SchemaOf<UserData> = Yup.object({
  email: Yup.string().required('Email harus diisi!').email('Format email tidak valid'),
});

const areas = `
  header
  content
  email
  role
  brand
`;

const UserDetailPage = () => {
  const { userId } = useParams();
  
  const {
    reset, register, handleSubmit, formState: { errors }, watch, setValue,
  } = useForm({
    // defaultValues: {
      // ...userData,
      // brandId: userData?.brand.id,
      // roleIds: userData?.role.id,
    // },
    resolver: yupResolver(schema)
  });

  const { isLoading, error, data, isFetching, refetch } = useQuery(
    `read-user-${userId}`,
    () => api.readUser(userId),
    {
      onSuccess: (data) => {
        setValue('name', data?.data.name);
        setValue('email', data?.data.email);
        
        if (data?.data?.brand?.id) {
          setValue('brandId', data?.data.brand.id);
        }
        if (data?.data?.role?.id) {
          setValue('roleIds', data?.data?.role?.id);
        }
      }
    }
  );

  const isAdmin = ['superadmin'].includes(data?.data?.role?.name);

  const userData = data?.data;

  const roleIds = watch('roleIds');
  const brandId = watch('brandId');

  useEffect(() => {
    reset(userData);
  }, [userData])

  const onSuccess = () => {
    navigate('/page/users');
    Toast({
      title: 'Update Berhasil',
      message: 'Data pengguna berhasil diupdate',
      type: 'success',
    });
  };
  
  

  const onError = (error: any) => {
    if (error) {
      console.log(error);
    }
  };
  
  const {
    isLoading: updateLoading,
    mutate,
  } = useMutation((values) => api.updateUser({
    user: {
      brand_id: values.brandId,
      email: values.email,
      name: values.name,
      role_ids: [values.roleIds],
    }
  }, userId), { onSuccess, onError });

  const { isLoading: brandsLoading, data: brands } = useQuery(
    `pages`,
    brandAPI.listBrands,
  );

  const brandOpts = brands?.data?.map(brand => {
    return {
      value: brand.id,
      label: brand.name
    }
  })

  const {
    isLoading: deleteLoading,
    mutate: removeUser,
  } = useMutation((values) => api.deleteUser(userId), {
    onSuccess: () => {
      navigate('/page/users');
      Toast({
        title: 'Delete Berhasil',
        message: 'Data pengguna berhasil dihapus',
        type: 'success',
      });
    },
    onError,
  });

  const borderStyle = {
    borderRadius: '12px',
    border: '1px solid',
    borderColor: 'gray1',
    bg: 'white',
    height: '40px',
  }

  return (
    <ApplicationShell>
      <Composition
        width={'100%'}
        inline
        areas={areas}
        templateRows={`1fr 12fr`}
        gap={20}
        padding={32}
      >
        {({ Header: HeaderWrapper, Content: ContentWrapper, Email, Role, Brand }) => (
          <>
            <HeaderWrapper>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Text variant="headline" color="#B9A511">Edit Pengguna</Text>
                <MyButton
                  isLoading={deleteLoading}
                  onClick={() => removeUser()}
                  style={{
                    height: 'fit-content'
                  }}
                >
                  Delete
                </MyButton>
              </Box>
            </HeaderWrapper>
            <ContentWrapper>
              <>
                <Card
                  variant='sheet'
                >
                  <Box as="form" onSubmit={(e) => e.preventDefault()}>
                    {/* <UserName marginBottom={15}>
                      <Label htmlFor="name" mb={2}>Nama User</Label>
                      <Input
                        {...register("name", { required: true })}
                        variant={errors.name && 'error' || 'input'}
                        name="name"
                        id="name"
                      />
                      {errors.name && (
                        <Text sx={{ fontSize: '12px'}}>{errors.name.message}</Text>
                      )}
                    </UserName> */}
                    <Email marginBottom={15}>
                      <Label htmlFor="email" mb={2}>Email</Label>
                      <Input
                        {...register("email", { required: true })}
                        variant={errors.email && 'error' || 'input'}
                        type="email"
                        name="email"
                        id="email"
                      />
                      {errors.email && (
                        <Text color="error" sx={{ fontSize: '12px'}}>{errors.email.message}</Text>
                      )}
                    </Email>
                    {/* <Password marginBottom={15}>
                      <Label htmlFor="password" mb={2}>Password</Label>
                      <Input
                        variant={errors.password && 'error' || 'input'}
                        type="password"
                        {...register("password")}
                      />
                      {errors.password && (
                        <Text sx={{ fontSize: '12px'}}>{errors.password.message}</Text>
                      )}
                    </Password>
                    <Password marginBottom={15}>
                      <Label htmlFor="password_confirmation" mb={2}>Password Confirmation</Label>
                      <Input
                        variant={(errors.password_confirmation) && 'error' || 'input'}
                        type="password"
                        {...register("password_confirmation")}
                      />
                      {errors.password_confirmation && (
                        <Text sx={{ fontSize: '12px'}}>{errors.password_confirmation.message}</Text>
                      )}
                    </Password> */}
                    <Brand marginBottom={15}>
                      <Selection
                        variant="input"
                        id="brandId"
                        name="brandId"
                        label="Brand"
                        sx={borderStyle}
                        onChange={(e) => setValue('brandId', e.target.value)}
                        value={brandId}
                        disabled={isAdmin}
                      >
                        {!isAdmin && brandOpts?.map(brand => (
                          <option value={brand.value}>{brand.label}</option>
                          ))}
                      </Selection>
                    </Brand>
                    <Role marginBottom={15}>
                      <Selection
                        variant="input"
                        id="roleIds"
                        name="roleIds"
                        label="Role"
                        sx={borderStyle}
                        onChange={(e) => {
                          setValue('roleIds', e.target.value)
                        }}
                        value={roleIds}
                        disabled={isAdmin}
                      >
                        {!isAdmin && (
                          <>
                            <option value="2">Marketing Analytics</option>
                            <option value="3">Brand</option>
                          </>
                        )}
                      </Selection>
                    </Role>
                    <MyButton
                      onClick={handleSubmit(mutate)}
                      isLoading={updateLoading}
                      style={{ float: 'right'}}
                      mt={4}
                    >
                      Update
                    </MyButton>
                  </Box>
                </Card>
              </>
            </ContentWrapper>
          </>
        )}
      </Composition>
    </ApplicationShell>
  )
}

export default UserDetailPage;