import * as React from 'react'
import { Composition } from 'atomic-layout'
import { Box, Button, Card, Container, Flex, IconButton, Message, Text } from 'theme-ui'
import MyButton from '../Button';
import { HiOutlineChartPie, HiOutlineHome, HiOutlineShoppingBag, HiOutlineUser } from 'react-icons/hi';
import HypefastLogo from "../../assets/hypefast.svg";
import colors from '../../gatsby-plugin-theme-ui/theme/colors'
import ProfileButton from '../ProfileButton';
import auth from '../../utils/auth';
import { Link, navigate } from 'gatsby';
import ScrolledDiv from '../ScrolledDiv';
import { useQuery } from 'react-query';
import api from '../../utils/api';

const areas = `
  menu content
`;

const isActive = ({ isPartiallyCurrent }) => {
  const style = {
    flexDirection: 'row',
    textDecoration: 'none',
    backgroundColor: "#F4F1DD",
    color: "#333333",
    cursor: "pointer",
    fontFamily: "inherit",
    borderRightWidth: 2,
    borderRightStyle: "solid",
    borderRightColor: "#333333",
    fontWeight: "bold",
    borderRadius: 0,
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "inset",
    letterSpacing: "headline",
    WebkitTapHighlightColor: "transparent",
    transition: "background-color .250s ease-in-out, color .250s ease-in-out",
    "&:disabled,&[disabled]": {
      cursor: "not-allowed",
      bg: "highlight",
      color: "muted",
    },
    ":focus,:hover": {},
    svg: {
      ml: -1,
      mr: 2,
    },
  };
  return isPartiallyCurrent ? { style } : { }
}

const MenuItem = ({ onClick, title, to, icon, isSuperAdmin }) => {
  // const { isLoading, error, data, isFetching } = useQuery(
  //   `profile`,
  //   api.profile,
  // );

  // const profile = data?.data
  // const isSuperAdmin = ['superadmin'].includes(profile?.role?.name);

  // React.useEffect(() => {
  //   if (isSuperAdmin) {
  //     navigate('/page/brands')
  //   }
  // }, [isSuperAdmin])

  return (
    // TODO: Try <a as={Link} or <Link as={Link} and see how it works.
    // Current way of styling is not favorable.
    <Link
      style={{
        display: (isSuperAdmin && ['My Pages', 'Analytics'].includes(title)) && 'none' ||
          (!isSuperAdmin && ['Brands', 'Users'].includes(title)) && 'none',
        flexDirection: 'row',
        textDecoration: 'none',
      }}
      to={to}
      getProps={isActive}
    >
      <MyButton
        full
        variant='transparent'
        textAlignment='flex-start'
        leftIcon={icon}
        onClick={onClick}
      >
        <Text>
          {title}
        </Text>
      </MyButton>
    </Link>
  )
}

// markup
const ApplicationShell = ({children, ...props}) => {
  const { isLoading, error, data, isFetching } = useQuery(
    `profile`,
    api.profile,
  );

  const profile = data?.data;
  const brandName = profile?.brand && (profile?.brand.name.charAt(0).toUpperCase() + profile?.brand.name.slice(1)) || 'Hypefast';
  const isSuperAdmin = ['superadmin'].includes(profile?.role?.name);

  return (
    <Composition
      height={'100vh'}
      width={'100%'}
      inline
      areas={areas}
      templateCols={`280px 3fr`}
      gap={20}
      // padding={32}
    >
      {({ Menu, Content }) => (
        <>
          <Menu>
            <Flex
              sx={{
                flexDirection: 'column',
                height: '100%',
                // p: 32, //TODO Change this to theme spacing value
                borderRightStyle: 'solid',
                borderRightColor: 'sunken',
                borderRightWidth: 1,
              }}
            >
              <Box
                p={32}
              >
                <IconButton
                  sx={{
                    width: '100%',
                  }}
                >
                  <HypefastLogo
                    width={'100%'}
                  />
                </IconButton>
              </Box>
              <Flex
                sx={{
                  flexDirection: 'column',
                }}
              >
                <MenuItem
                  isSuperAdmin={isSuperAdmin}
                  icon={<HiOutlineHome width='100%' height='100%' />}
                  title={'My Pages'}
                  to='/page/dashboard'
                />
                <MenuItem
                  isSuperAdmin={isSuperAdmin}
                  icon={<HiOutlineChartPie width='100%' height='100%' />}
                  title={'Analytics'}
                  to='/page/analytics'
                />
                <MenuItem
                  isSuperAdmin={isSuperAdmin}
                  icon={<HiOutlineShoppingBag width='100%' height='100%' />}
                  title={'Brands'}
                  to='/page/brands'
                />
                <MenuItem
                  isSuperAdmin={isSuperAdmin}
                  icon={<HiOutlineUser width='100%' height='100%' />}
                  title={'Users'}
                  to='/page/users'
                />
              </Flex>
            </Flex>
          </Menu>
          <Content as={ScrolledDiv}>
            <Flex
              sx={{
                width: '100%',
                justifyContent: 'end',
                p: 32,
              }}
            >
              <ProfileButton
                sx={{
                  width: 200,
                  cursor: 'pointer'
                }}
                onLogout={() => {
                  auth.removeToken()
                  navigate('/');
                }}
              >
                <Text
                >
                  {brandName}
                </Text>
              </ProfileButton>
            </Flex>
            {children}
          </Content>
        </>
      )}
    </Composition>
  )
}

export default ApplicationShell
