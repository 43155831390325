import * as React from 'react'
import { Box } from 'theme-ui';
import { Editor, useEditor } from '@craftjs/core'
import { QueryClient, QueryClientProvider } from 'react-query'
import RootContainer from '../../components/UserComponents/RootContainer';
import Text from '../../components/UserComponents/Text'
import Video from '../../components/UserComponents/Video'
import SocialIcons from '../../components/UserComponents/SocialIcons';
import SocialIcon from '../../components/UserComponents/SocialIcon'
import Link from '../../components/UserComponents/Link';
import Links from '../../components/UserComponents/Links'
import Image from '../../components/UserComponents/Image'
import Divider from '../../components/UserComponents/Divider'
import Products from '../../components/UserComponents/Products'
import Product from '../../components/UserComponents/Product'
import Promo from '../../components/UserComponents/Promo'
import Layout from './Layout';


const RenderNode = ({ render }) => {
  return (
    <Box
      sx={{
        height: '100%',
        cursor: 'pointer',
      }}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      {render}
    </Box>
  )
}

const PageEditor = (props) => {
  return (
    <Editor
      enabled
      onRender={RenderNode}
      resolver={{
        RootContainer, Text, Video, SocialIcons, SocialIcon, Link, Links, Image, Divider, Products, Product, Promo
      }}
    >
      <Layout />
    </Editor>
  )
}

export default PageEditor
