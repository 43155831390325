/**@jsx jsx */
import React, { useState } from "react";
import { Composition } from 'atomic-layout';
import { Box, Card, Button, Container, Flex, IconButton, Image, Text } from '@theme-ui/components';
import { MdEdit, MdOutlineRemoveRedEye } from "react-icons/md";
import { FaEllipsisV, FaPlus, FaShare } from "react-icons/fa";
import { jsx } from 'theme-ui'
import { QueryClientProvider, useQuery } from "react-query";
import { navigate } from "gatsby";
import PageCards from "./compositions/Content/PageCards";
import api from '../../utils/api';
import ApplicationShell from "../../components/Layout/ApplicationShell";

const areas = `
  header
  content
`;

const HomePage = () => {
  const [hovered, setHovered] = useState(false);

  function handleEvent(type: string, event: any) {
    switch (type) {
      case 'mouseEnter':
        return setHovered(true);
      case 'mouseLeave':
        return setHovered(false);
      default:
        return;
    }
  }
  
  const { isLoading, error, data, isFetching } = useQuery(
    `pages`,
    api.pages,
  );


  return (
    <ApplicationShell>
      <Composition
        width={'100%'}
        inline
        areas={areas}
        templateRows={`1fr 12fr`}
        gap={20}
        padding={32}
      >
        {({ Header: HeaderWrapper, Content: ContentWrapper }) => (
          <>
            <HeaderWrapper>
              <Box>
                <Text variant="headline" color="#B9A511">Dashboard</Text>
              </Box>
              <Box>
                <Text variant="body">Desain page kamu dan pantau sekilas aktivitas terbaru</Text>        
              </Box>
            </HeaderWrapper>
            <ContentWrapper>
              <>
                <Card
                  variant='sheet'
                >
                  <PageCards />
                </Card>
              </>
            </ContentWrapper>
          </>
        )}
      </Composition>
    </ApplicationShell>
  )
}

export default HomePage;